import $ from 'jquery';

import './assets/scss/main.scss';

const to2d = num => {
  return num < 10 ? `0${num}` : num;
};

const handleBargainSubmit = e => {
  e.preventDefault();
  const data = {
    action: 'bargainformmail',
    bargain: document.title,
  };
  let invalid = null;
  const form = $(e.target).parent('form');
  if (form.data('busy')) {
    return;
  }
  form.data('busy', true);
  form.find('.invalid').removeClass('invalid');
  const input = form.find('input');
  data.contact = input.val();
  if (data.contact.length < 5) {
    input.addClass('invalid');
    input.focus();
    form.data('busy', false);
    return;
  }
  $.ajax({
    url: window.ajaxurl,
    type: 'POST',
    data: data,
    success: res => {
      form.find('.thanks').addClass('shown');
      form.find('input,textarea').val('');
    },
    error: (xhr, text, err) => {
      console.log('error', xhr, text, err);
    },
    complete: (xhr, text) => {
      form.data('busy', false);
      setTimeout(() => {
        form.find('.shown').removeClass('shown');
      }, 3000);
    },
  });
};


const handleSubmit = e => {
  e.preventDefault();
  const data = {
    action: 'formmail',
  };
  let invalid = null;
  const form = $(e.target).parent('form');
  if (form.data('busy')) {
    return;
  }
  form.data('busy', true);
  form.find('.invalid').removeClass('invalid');
  form.find('.error1,.error2,.error3').removeClass('shown');
  form.find('[data-key]').each((i, el) => {
    const div = $(el);
    const key = div.data('key');
    const field = div.find('input,textarea');
    const val = field.val().trim();
    const re = div.data('re');
    let valid = true;
    if (re) {
      const rex = new RegExp(re);
      valid = !!rex.exec(val);
    }
    if (!valid) {
      div.addClass('invalid');
      invalid = field;
      return false;
    }
    data[key] = val;
  });
  const checked = $('#form-accept').prop('checked');
  if (!checked) {
    form.find('.error2').addClass('shown');
    form.data('busy', false);
    return;
  }
  if (invalid || !checked) {
    invalid.focus();
    form.find('.error1').addClass('shown');
    form.data('busy', false);
    return;
  }
  if (window.location.search.match(/^\?(m\d+)/)) {
    const ad = window.location.search.replace(/^\?(m\d+)/, '$1');
    data['ad'] = ad;
  }
  $.ajax({
    url: window.ajaxurl,
    type: 'POST',
    data: data,
    success: res => {
      form.find('.thanks').addClass('shown');
      form.find('input,textarea').val('');
    },
    error: (xhr, text, err) => {
      form.find('.error3').addClass('shown');
    },
    complete: (xhr, text) => {
      form.data('busy', false);
      setTimeout(() => {
        form.find('.shown').removeClass('shown');
      }, 3000);
    },
  });
};

$(() => {
  $('header .toggle').on('click', e => {
    e.preventDefault();
    $('header').toggleClass('toggled');
  });
  $('form.contact').on('submit', e => e.preventDefault());
  $('form.contact input[type="button"]').on('click', handleSubmit);
  $('form.bargainform').on('submit', e => e.preventDefault());
  $('form.bargainform button').on('click', handleBargainSubmit);
  $('form.bargainform input').on('keyup', e => {
    var elm = $(e.target);
    elm.removeClass('invalid');
  });
  $('section.bargainlist p.counter').each((i, el) => {
    const elm = $(el);
    const from = parseInt(elm.data('from'));
    const to = parseInt(elm.data('to'));
    const now = parseInt(elm.data('now'));
    if (from > now) {
      elm.append('Ei vielä saatavilla');
    } else if (to < now) {
      elm.append('Ei enää saatavilla');
    } else {
      const delta = to - now;
      elm.data('current', delta);
      setInterval(() => {
        const last = parseInt(elm.data('current'));
        const d = Math.floor(last / 86400);
        const h = Math.floor((last % 86400)/3600);
        const m = Math.floor((last % 3600)/60);
        const s = Math.floor(last % 60);
        if (last > 0) {
          elm.data('current', last - 1);
          const arr = [];
          if (d > 0) {
            arr.push(to2d(d));
          }
          if (h > 0) {
            arr.push(to2d(h));
          }
          if (m > 0) {
            arr.push(to2d(m));
          }
          if (arr.length > 0) {
            arr.push(to2d(s));
          } else {
            arr.push(s);
          }
          elm.empty().append('Saatavilla vielä ' + arr.join(':'));
        } else {
          elm.empty().append('En enää saatavilla');
        }
      }, 1000);
    }
  });
});
